<template>
    <div>
        <v-sheet class="dense-inputs">
            <div class="success pt-0 pb-3 px-2 lighten-1 border-rad-sm">
                <v-form
                    ref="filterForm"
                    v-model="validForm"
                    lazy-validation
                >
                    <v-row no-gutters>
                        <v-col cols="2" class="pt-2 pb-0 ma-0">
                            <v-row no-gutters style="padding-bottom: 1px">
                                <v-col cols="12" class="font-xs font-weight-bold">{{ $t('message.from') }}:</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-0">
                                <v-col cols="6">
                                    <v-select
                                        :items="months"
                                        :label="$t('message.month')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details
                                        item-text="Month.text"
                                        item-value="Month.id"
                                        solo
                                        v-model="filterOptions.monthFrom"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <div class="px-1">
                                        <v-select
                                            :items="years"
                                            :label="$t('message.year')"
                                            :rules="rules.yearFrom"
                                            autocomplete="off"
                                            class="grey lighten-4"
                                            dense
                                            hide-details="auto"
                                            item-text="Year.text"
                                            item-value="Year.id"
                                            required
                                            solo
                                            v-model="filterOptions.yearFrom"
                                            @change="validateYear('yearFrom')"
                                        ></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters style="padding-bottom: 5px; padding-top: 3px">
                                <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.to') }}:</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-0">
                                <v-col cols="6">
                                    <v-select
                                        :items="months"
                                        :label="$t('message.month')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details
                                        item-text="Month.text"
                                        item-value="Month.id"
                                        solo
                                        v-model="filterOptions.monthTo"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <div class="px-1">
                                        <v-select
                                            :items="years"
                                            :label="$t('message.year')"
                                            :rules="rules.yearTo"
                                            autocomplete="off"
                                            class="grey lighten-4"
                                            dense
                                            hide-details="auto"
                                            item-text="Year.text"
                                            item-value="Year.id"
                                            required
                                            solo
                                            v-model="filterOptions.yearTo"
                                            @change="validateYear('yearTo')"
                                        ></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="pb-0 pl-0 pr-1">
                                    <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.date') }}</span>
                                    <v-select
                                        :items="dateFields"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details
                                        item-text="Datefield.text"
                                        item-value="Datefield.id"
                                        required
                                        solo
                                        v-model="filterOptions.dateField"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0">
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.destination') }}</span>
                                <v-autocomplete
                                    :items="salesDestinations"
                                    :label="$t('message.selectOptionOptions')"
                                    :loading="loading.filter.destinations"
                                    :rules="rules.destinations"
                                    :value="filterOptions.destinations"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Destination.title"
                                    item-value="Destination.id"
                                    multiple
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('destinations')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.destinations.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterDestinationsIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('destination',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.destinations.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.destinations.length > 0 && filterOptions.destinations.find(opt => opt.Destination.id == item.Destination.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.destinations.selectedAll == false && filterOptions.destinations.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.destinations.selectedAll == false && filterOptions.destinations.length > 0 && !filterOptions.destinations.find(opt => opt.Destination.id == item.Destination.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Destination.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.destinations.length > 0">{{ item.Destination.title }}</span>
                                        <span v-if="index === 1  && filterOptions.destinations.length > 0"> (+{{ (filterOptions.destinations.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.destinations.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.marketing') }}</span>
                                <div class="d-flex flex-row">
                                    <v-autocomplete
                                        :items="salesSalescolleagues"
                                        :label="$t('message.selectOptionOptions')"
                                        :loading="loading.filter.salescolleagues"
                                        :rules="rules.salescolleagues"
                                        :value="filterOptions.salescolleagues"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details="auto"
                                        item-text="Salescontact.title"
                                        item-value="Salescontact.id"
                                        multiple
                                        required
                                        return-object
                                        solo
                                    >
                                        <template v-slot:prepend-item>
                                            <v-list-item
                                                dense
                                                ripple
                                                @click="toggleFilterAll('salescolleagues')"
                                            >
                                                <v-list-item-action>
                                                    <v-icon :color="filterAll.salescolleagues.selectedAll ? 'indigo darken-4' : ''">
                                                        {{ filterSalescolleaguesIcon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-1"></v-divider>
                                        </template>
                                        <template v-slot:item="{item}">
                                            <v-list-item
                                                dense
                                                ripple
                                                @click="addToRemoveFromFilterOptions('salescolleague',item)"
                                            >
                                                <v-list-item-action>
                                                    <v-icon color="blue darken-2" v-if="filterAll.salescolleagues.selectedAll">mdi-checkbox-marked</v-icon>
                                                    <v-icon color="blue darken-2" v-if="filterOptions.salescolleagues.length > 0 && filterOptions.salescolleagues.find(opt => opt.Salescontact.id == item.Salescontact.id)">mdi-checkbox-marked</v-icon>
                                                    <v-icon v-if="filterAll.salescolleagues.selectedAll == false && filterOptions.salescolleagues.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                    <v-icon v-if="filterAll.salescolleagues.selectedAll == false && filterOptions.salescolleagues.length > 0 && !filterOptions.salescolleagues.find(opt => opt.Salescontact.id == item.Salescontact.id)">mdi-checkbox-blank-outline</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.Salescontact.title }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:selection="{item, index}">
                                            <span v-if="index < 1 && filterOptions.salescolleagues.length > 0">{{ item.Salescontact.title }}</span>
                                            <span v-if="index === 1  && filterOptions.salescolleagues.length > 0"> (+{{ (filterOptions.salescolleagues.length - 1).toString() + " " + $t('message.others') }})</span>
                                        </template>
                                        <template v-slot:label>
                                            <span v-if="filterAll.salescolleagues.selectedAll">{{ $t('message.all') }}</span>
                                            <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-checkbox
                                                :label="$t('message.sus')"
                                                :value="filterOptions.salescolleague_include_suspended"
                                                class="font-weight-bold my-0 py-0 ml-1 black-checkbox-outline"
                                                color="indigo darken-3"
                                                dense
                                                height="36px"
                                                hide-details
                                                v-on="on"
                                            ></v-checkbox>
                                        </template>
                                        <span>{{ $t('message.includeSuspended') }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <div class="py-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.certification') }}</span>
                                <v-select
                                    :items="certifications"
                                    :label="$t('message.selectOptionOptions')"
                                    :loading="loading.filter.certifications"
                                    :rules="rules.buyers"
                                    :value="filterOptions.certifications"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Certification.title"
                                    item-value="Certification.id"
                                    multiple
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('certifications')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.certifications.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterCertificationsIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('certification',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.certifications.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.certifications.length > 0 && filterOptions.certifications.find(opt => opt.Certification.id == item.Certification.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.certifications.selectedAll == false && filterOptions.certifications.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.certifications.selectedAll == false && filterOptions.certifications.length > 0 && !filterOptions.certifications.find(opt => opt.Certification.id == item.Certification.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Certification.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.certifications.length > 0">{{ item.Certification.title }}</span>
                                        <span v-if="index === 1  && filterOptions.certifications.length > 0"> (+{{ (filterOptions.certifications.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.certifications.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0">
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.buyer') }}</span>
                                <v-autocomplete
                                    :items="salesCustomers"
                                    :label="$t('message.selectOptionOptions')"
                                    :loading="loading.filter.buyers"
                                    :rules="rules.buyers"
                                    :value="filterOptions.customers"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Customer.title"
                                    item-value="Customer.id"
                                    multiple
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('customers')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.customers.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterCustomersIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('customer',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.customers.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.customers.length > 0 && filterOptions.customers.find(opt => opt.Customer.id == item.Customer.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.customers.selectedAll == false && filterOptions.customers.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.customers.selectedAll == false && filterOptions.customers.length > 0 && !filterOptions.customers.find(opt => opt.Customer.id == item.Customer.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Customer.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.customers.length > 0">{{ item.Customer.title }}</span>
                                        <span v-if="index === 1  && filterOptions.customers.length > 0"> (+{{ (filterOptions.customers.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.customers.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.supplier') }}</span>
                                <v-autocomplete
                                    :items="salesSuppliers"
                                    :label="$t('message.supplier')"
                                    :loading="loading.filter.suppliers"
                                    :rules="rules.suppliers"
                                    :value="filterOptions.suppliers"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Supplier.title"
                                    item-value="Supplier.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('suppliers')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.suppliers.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterSuppliersIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('supplier',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.suppliers.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.suppliers.length > 0 && filterOptions.suppliers.find(opt => opt.Supplier.id == item.Supplier.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.suppliers.selectedAll == false && filterOptions.suppliers.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.suppliers.selectedAll == false && filterOptions.suppliers.length > 0 && !filterOptions.suppliers.find(opt => opt.Supplier.id == item.Supplier.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Supplier.title + (item.Supplier.status == 'suspended' ? ' ('+ $t('message.suspended') +')' : '')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.suppliers.length > 0">{{ item.Supplier.title + (item.Supplier.status == 'suspended' ? ' ('+ $t('message.suspended') +')' : '') }}</span>
                                        <span v-if="index === 1  && filterOptions.suppliers.length > 0"> (+{{ (filterOptions.suppliers.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.suppliers.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.agent') }}</span>
                                <v-autocomplete
                                    :items="salesAgents"
                                    :label="$t('message.agent')"
                                    :loading="loading.filter.agents"
                                    :rules="rules.agents"
                                    :value="filterOptions.agents"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details
                                    item-text="Agent.title"
                                    item-value="Agent.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('agents')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.agents.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterAgentsIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('agent',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.agents.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.agents.length > 0 && filterOptions.agents.find(opt => opt.Agent.id == item.Agent.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.agents.selectedAll == false && filterOptions.agents.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.agents.selectedAll == false && filterOptions.agents.length > 0 && !filterOptions.agents.find(opt => opt.Agent.id == item.Agent.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Agent.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.agents.length > 0">{{ item.Agent.title }}</span>
                                        <span v-if="index === 1  && filterOptions.agents.length > 0"> (+{{ (filterOptions.agents.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.agents.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.agent') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0">
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.species') }}</span>
                                <v-autocomplete
                                    :items="salesSpecies"
                                    :label="$t('message.species')"
                                    :loading="loading.filter.species"
                                    :rules="rules.species"
                                    :value="filterOptions.species"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Species.title"
                                    item-value="Species.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('species')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.species.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterSpeciesIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('species',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.species.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.species.length > 0 && filterOptions.species.find(opt => opt.Species.id == item.Species.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.species.selectedAll == false && filterOptions.species.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.species.selectedAll == false && filterOptions.species.length > 0 && !filterOptions.species.find(opt => opt.Species.id == item.Species.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Species.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.species.length > 0">{{ item.Species.title }}</span>
                                        <span v-if="index === 1  && filterOptions.species.length > 0"> (+{{ (filterOptions.species.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.species.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.species') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.gradeGroup') }}</span>
                                <v-autocomplete
                                    :items="salesGradeGroups"
                                    :label="$t('message.gradeGroup')"
                                    :loading="loading.filter.gradeGroups"
                                    :rules="rules.gradeGroups"
                                    :value="filterOptions.gradeGroups"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Grade.title"
                                    item-value="Grade.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('gradeGroups')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.gradeGroups.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterGradesIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('gradeGroup',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.gradeGroups.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.gradeGroups.length > 0 && filterOptions.gradeGroups.find(opt => opt.GradeGroup.id == item.GradeGroup.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.gradeGroups.selectedAll == false && filterOptions.gradeGroups.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.gradeGroups.selectedAll == false && filterOptions.gradeGroups.length > 0 && !filterOptions.gradeGroups.find(opt => opt.GradeGroup.id == item.GradeGroup.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.GradeGroup.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.gradeGroups.length > 0">{{ item.GradeGroup.title }}</span>
                                        <span v-if="index === 1  && filterOptions.gradeGroups.length > 0"> (+{{ (filterOptions.gradeGroups.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.gradeGroups.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.gradeGroup') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.thickness') }}</span>
                                <v-autocomplete
                                    :items="salesThickness"
                                    :label="$t('message.thickness')"
                                    :loading="loading.filter.thickness"
                                    :rules="rules.thickness"
                                    :value="filterOptions.thickness"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Size.title"
                                    item-value="Size.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('thickness')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.thickness.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterThicknessIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('thickness',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.thickness.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.thickness.length > 0 && filterOptions.thickness.find(opt => opt.Thickness.id == item.Thickness.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.thickness.selectedAll == false && filterOptions.thickness.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.thickness.selectedAll == false && filterOptions.thickness.length > 0 && !filterOptions.thickness.find(opt => opt.Thickness.id == item.Thickness.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Thickness.title + (item.Thickness.ms == 1 ? ' mm' : '')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.thickness.length > 0">{{ item.Thickness.title }}</span>
                                        <span v-if="index === 1  && filterOptions.thickness.length > 0"> (+{{ (filterOptions.thickness.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.thickness.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.thickness') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0">
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.status') }}</span>
<!--                                <v-select-->
<!--                                    :items="salesStatuses"-->
<!--                                    :label="$t('message.status')"-->
<!--                                    :loading="loading.filter.status"-->
<!--                                    :rules="[rules.status]"-->
<!--                                    autocomplete="off"-->
<!--                                    class="grey lighten-4"-->
<!--                                    dense-->
<!--                                    hide-details="auto"-->
<!--                                    item-text="Salestatus.text"-->
<!--                                    item-value="Salestatus.id"-->
<!--                                    solo-->
<!--                                    v-model="filterOptions.status"-->
<!--                                >-->
<!--                                    <template v-slot:item="{item}">-->
<!--                                        <div>{{ item.Salestatus.text }} <v-icon small v-if="item.Salestatus.iconColor != null" :color="item.Salestatus.iconColor">fiber_manual_record</v-icon></div>-->
<!--                                    </template>-->
<!--                                    <template v-slot:selection="{item, index}">-->
<!--                                        <span>{{ item.Salestatus.text }}</span>-->
<!--                                    </template>-->
<!--                                </v-select>-->
                                <v-select
                                    :items="salesStatuses"
                                    :label="$t('message.status')"
                                    :loading="loading.filter.status"
                                    :rules="rules.status"
                                    :value="filterOptions.status"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Salestatus.text"
                                    item-value="Salestatus.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('status')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.status.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterStatusIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('status',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.status.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.status.length > 0 && filterOptions.status.find(opt => opt.Salestatus.id == item.Salestatus.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.status.selectedAll == false && filterOptions.status.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.status.selectedAll == false && filterOptions.status.length > 0 && !filterOptions.status.find(opt => opt.Salestatus.id == item.Salestatus.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Salestatus.text }} <v-icon small v-if="item.Salestatus.iconColor != null" :color="item.Salestatus.iconColor">fiber_manual_record</v-icon></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.status.length > 0">{{ item.Salestatus.text }}</span>
                                        <span v-if="index === 1  && filterOptions.status.length > 0"> (+{{ (filterOptions.status.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.status.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.status') }}</span>
                                    </template>
                                </v-select>
                            </div>
                            <div class="pb-0 px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.marketSegment') }}</span>
                                <v-autocomplete
                                    :items="salesMarketsegments"
                                    :label="$t('message.marketSegment')"
                                    :loading="loading.filter.marketsegments"
                                    :rules="rules.marketsegments"
                                    :value="filterOptions.marketsegments"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Marketsegment.title"
                                    item-value="Marketsegment.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                    v-model="filterOptions.marketsegments"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('marketsegments')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.marketsegments.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterMarketSegmentsIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('marketsegment',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.marketsegments.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.marketsegments.length > 0 && filterOptions.marketsegments.find(opt => opt.Marketsegment.id == item.Marketsegment.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.marketsegments.selectedAll == false && filterOptions.marketsegments.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.marketsegments.selectedAll == false && filterOptions.marketsegments.length > 0 && !filterOptions.marketsegments.find(opt => opt.Marketsegment.id == item.Marketsegment.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Marketsegment.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.marketsegments.length > 0">{{ item.Marketsegment.title }}</span>
                                        <span v-if="index === 1  && filterOptions.marketsegments.length > 0"> (+{{ (filterOptions.marketsegments.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.marketsegments.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.marketSegment') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.product') }}</span>
                                <v-autocomplete
                                    :items="salesProductgroups"
                                    :label="$t('message.productGroup')"
                                    :loading="loading.filter.productgroups"
                                    :rules="rules.productgroups"
                                    :value="filterOptions.productgroups"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Productgroup.title"
                                    item-value="Productgroup.id"
                                    multiple
                                    required
                                    return-object
                                    solo
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('productgroups')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.productgroups.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterProductGroupsIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('productgroup',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.productgroups.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.productgroups.length > 0 && filterOptions.productgroups.find(opt => opt.Productgroup.id == item.Productgroup.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.productgroups.selectedAll == false && filterOptions.productgroups.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.productgroups.selectedAll == false && filterOptions.productgroups.length > 0 && !filterOptions.productgroups.find(opt => opt.Productgroup.id == item.Productgroup.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Productgroup.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.productgroups.length > 0">{{ item.Productgroup.title }}</span>
                                        <span v-if="index === 1  && filterOptions.productgroups.length > 0"> (+{{ (filterOptions.productgroups.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.productgroups.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.product') }}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0">
                            <div class="px-1">
                                <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.contractType') }}</span>
                                <v-select
                                    :items="salesTypes"
                                    :label="$t('message.contractType')"
                                    autocomplete="off"
                                    class="grey lighten-4"
                                    dense
                                    hide-details="auto"
                                    item-text="Salestype.text"
                                    item-value="Salestype.id"
                                    multiple
                                    return-object
                                    solo
                                    v-model="filterOptions.types"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="toggleFilterAll('types')"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="filterAll.types.selectedAll ? 'indigo darken-4' : ''">
                                                    {{ filterTypeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{$t('message.all')}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <v-list-item
                                            dense
                                            ripple
                                            @click="addToRemoveFromFilterOptions('type',item)"
                                        >
                                            <v-list-item-action>
                                                <v-icon color="blue darken-2" v-if="filterAll.types.selectedAll">mdi-checkbox-marked</v-icon>
                                                <v-icon color="blue darken-2" v-if="filterOptions.types.length > 0 && filterOptions.types.find(opt => opt.Salestype.id == item.Salestype.id)">mdi-checkbox-marked</v-icon>
                                                <v-icon v-if="filterAll.types.selectedAll == false && filterOptions.types.length == 0">mdi-checkbox-blank-outline</v-icon>
                                                <v-icon v-if="filterAll.types.selectedAll == false && filterOptions.types.length > 0 && !filterOptions.types.find(opt => opt.Salestype.id == item.Salestype.id)">mdi-checkbox-blank-outline</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.Salestype.text }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{item, index}">
                                        <span v-if="index < 1 && filterOptions.types.length > 0">{{ item.Salestype.text }}</span>
                                        <span v-if="index === 1  && filterOptions.types.length > 0"> (+{{ (filterOptions.types.length - 1).toString() + " " + $t('message.others') }})</span>
                                    </template>
                                    <template v-slot:label>
                                        <span v-if="filterAll.types.selectedAll">{{ $t('message.all') }}</span>
                                        <span v-else>{{ $t('message.selectOptionOptions') }}</span>
                                    </template>
                                </v-select>
                                <v-radio-group
                                    class="grey lighten-4 pt-0 mt-6 font-sm d-md-none d-lg-flex rounded-md elevation-2"
                                    dense
                                    hide-details
                                    row
                                    v-model="filterOptions.shipped"
                                >
                                    <v-radio
                                        :label="$t('message.all')"
                                        value="A"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('message.shipped')"
                                        value="S"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('message.unshipped')"
                                        value="U"
                                    ></v-radio>
                                </v-radio-group>
                                <div class="d-flex d-lg-none flex-column pt-2">
                                    <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.shipmentStatus') }}</span>
                                    <v-select
                                        :items="options.shipmentStatus"
                                        :label="$t('message.shipmentStatus')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details
                                        solo
                                        v-model="filterOptions.shipped"
                                    ></v-select>
                                </div>
                                <v-row no-gutters>
                                    <v-col
                                        cols="12"
                                        class="pa-0 mt-7 mb-0 d-flex flex-row"
                                    >
                                        <v-btn color="grey white--text" block small @click="loadSales()">
                                            <span v-if="loading.fetch">{{ $t('message.fetching') }}</span>
                                            <span v-else>{{ $t('message.fetch') }}</span>
                                        </v-btn>
                                        <v-btn :loading="loading.clear" color="grey white--text" block small class="ml-2" @click="resetFilters()">{{ $t('message.clearAll') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </v-sheet>
        <v-overlay
            :value="loading.items"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [50,100,200,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="Sales"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            fixed-header
            id="appicReportTable"
            item-key="Sale.item_id"
        >
            <template v-slot:top>
                <v-row no-gutters class="pb-2">
                    <v-col cols="4" class="d-flex flex-row mt-2">
                        <div class="font-sm ml-3">
                            <v-icon small color="pink lighten-3">fiber_manual_record</v-icon>{{ $t('message.contractUnsigned') }}
                        </div>
                        <div class="font-sm ml-3">
                            <v-icon small color="blue darken-4">fiber_manual_record</v-icon>{{ $t('message.poUnsigned') }}
                        </div>
                        <div class="font-sm ml-3">
                            <v-icon small color="yellow lighten-3">fiber_manual_record</v-icon>{{ $t('message.unsignedWhPo') }}
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <ExportTableAppicReportV2
                        :export-params="exportAppicReportParams"
                        class="mr-2"
                    ></ExportTableAppicReportV2>
                </v-row>
            </template>
            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{header}">
                <span>{{ header.text }}</span>
            </template>
            <template v-slot:item.Sale.document_id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateContract(item.Sale.document_id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Contract')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>
                        <!--added 2022-03-14-->
                        <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Sale.Purchaseorder.id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && [1,2].includes(item.Sale.salestatus_id) && item.Sale.Purchaseorder.id != null && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                        <!---->
                        <v-list-item class="font-sm" @click="updateShipment(item.Sale.document_id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>

                        <!--added 2022-03-14-->
                        <v-list-item class="font-sm" @click="updateShippingInstruction(item.Sale.Sda.id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Shipment') && item.Sale.Sda.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingInstruction') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateLca(item.Sale.Lca.id)" v-if="[1,2,5].includes(item.Sale.salestype_id) && item.Sale.Lca.id != null && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateLca') }}</v-list-item>
                        <!---->

                        <!--added 2022-02-11-->
                        <v-list-item class="font-sm" @click="updateEpr(item.Sale.document_id)" v-if="[100].includes(item.Sale.salestype_id) && $can('update','Epr')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateEpr') }}</v-list-item>

                        <!--added 2022-03-14-->
                        <v-list-item class="font-sm" @click="updatePackingList(item.Sale.Pldo.id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Shipment') && item.Sale.Pldo.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateInvoice(item.Sale.Invoice.id)" v-if="[1,5].includes(item.Sale.salestype_id) && $can('update','Invoice') && item.Sale.Invoice.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateDebitNote(item.Sale.Debitnote.id)" v-if="[2].includes(item.Sale.salestype_id) && $can('update','DebitNote') && item.Sale.Debitnote.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateContractPayments(item.Sale.document_id)" v-if="[1,2,3,5].includes(item.Sale.salestype_id) && $can('update','Payment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                        <!---->

                        <!--added 2021-09-30-->
                        <v-list-item class="font-sm" @click="viewGrossMargin(item)" v-if="[1,5,99].includes(item.Sale.salestype_id) && $can('read','GrossMargin')"><v-icon color="black lighten-1" x-small class="mr-1">fas fa-funnel-dollar</v-icon>{{ $t('message.grossMargin') }}</v-list-item>

                        <v-list-item class="font-sm" @click="viewPdf(item)" v-if="item.Sale.salestype_id == 2 && $can('read','Contract')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>
                        <PrintContractButton
                            :update-mode="true"
                            :list-mode="true"
                            :contract-id="item.Sale.document_id"
                            v-if="[1,5].includes(item.Sale.salestype_id) && $can('read','Contract')"
                        />

                        <v-list-item class="font-sm" @click="viewSignedPdf(item)" v-if="[1,5].includes(item.Sale.salestype_id) && (item.Sale.TmsSignedOrder.location != null || item.Sale.SignedOrder.location != null) && $can('read','Contract')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedContract') }}</v-list-item>
<!--                        <v-list-item class="font-sm" @click="viewPoPdf(item)" v-if="[1,3,5,6].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','Po')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.po') }}</v-list-item>-->
<!--                        <v-list-item class="font-sm" @click="viewPoPdf(item)" v-if="[3].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','WarehousePo') && $can('read','Po') == false"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.po') }}</v-list-item>-->


                        <PrintPurchaseOrderButton
                            :update-mode="true"
                            :list-mode="true"
                            :purchase-order-id="item.Sale.Purchaseorder.id"
                            v-if="[1,3,5,6].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','Po')"
                        />
                        <PrintPurchaseOrderButton
                            :update-mode="true"
                            :list-mode="true"
                            :purchase-order-id="item.Sale.Purchaseorder.id"
                            v-if="[3].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','WarehousePo') && $can('read','Po') == false"
                        />


                        <v-list-item class="font-sm" @click="viewSignedPoPdf(item)" v-if="[1,3,5,6].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && (item.Sale.TmsSignedPurchaseOrder.location != null || item.Sale.SignedPurchaseOrder.location != null) && $can('read','Po')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>

                        <!--added 2022-02-11-->
                        <v-list-item class="font-sm" @click="viewEprPoPdf(item)" v-if="[100].includes(item.Sale.salestype_id) && $can('read','Epr')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.po') }}</v-list-item>
                        <v-list-item class="font-sm" @click="viewSignedEprPoPdf(item)" v-if="[100].includes(item.Sale.salestype_id) && item.Sale.SignedPurchaseOrder.location != null && $can('read','Epr')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>

                        <v-list-item
                            class="font-sm"
                            @click="openShippingDocumentsDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title ))"
                            v-if="['DS','CS','PO','SS','PO-EXP'].includes(item.Sale.type) && false"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.shippingDocuments') }}
                        </v-list-item>
                        <v-list-item
                            class="font-sm"
                            @click="openShippingInfoDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('read','Shipment') && false"
                        >
                            <v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>
                            {{ $t('message.shippingInfo') }}
                        </v-list-item>

                        <!--added 2022-03-14-->
                        <v-list-item
                            class="font-sm"
                            @click="openNonShippingDocumentsDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && [1,2].includes(item.Sale.salestatus_id)"
                        >
                            <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                            {{ $t('message.otherContractDocuments') }}
                        </v-list-item>
                        <!---->

                        <!--added 2021-08-31-->
                        <v-list-item
                            class="font-sm"
                            @click="openShippingInfoAndDocumentsDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title))"
                            v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && [1,2].includes(item.Sale.salestatus_id)"
                        >
                            <v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>
                            {{ $t('message.shippingInfoAndDocuments') }}
                        </v-list-item>
                        <!---->

                        <v-list-item class="font-sm" @click="updateSalesOrder(item.Sale.So.id)" v-if="[99].includes(item.Sale.salestype_id) && $can('update','SalesOrder')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSalesOrder')  }}</v-list-item>
                        <v-list-item class="font-sm" @click="viewSalesOrderPdf(item)" v-if="[99].includes(item.Sale.salestype_id) && $can('read','SalesOrder')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.salesOrder') }}</v-list-item>
                        <v-list-item class="font-sm" @click="viewLocalSalesInvoicePdf(item)" v-if="[99].includes(item.Sale.salestype_id) && $can('read','SalesOrder') && item.Sale.document_id != null"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.invoice') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.Sale.type="{item}">
                <span class="ml-1">{{ item.Sale.type.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Sale.title="{item}">
                <span class="font-weight-bold">
                    <v-icon small color="pink lighten-3" v-if="item.Sale.signed != 1">fiber_manual_record</v-icon>
                    <v-icon small color="blue darken-4" v-if="(item.Sale.posigned != 1 || item.Sale.posigned == null) && item.Sale.Purchaseorder.id != null && [1,5].includes(item.Sale.salestype_id)">fiber_manual_record</v-icon>
                    <v-icon small color="yellow lighten-3" v-if="item.Sale.posigned != 1 && [3].includes(item.Sale.salestype_id)">fiber_manual_record</v-icon>
                    <span class="font-weight-bold" v-if="item.Sale.type != 'WH' && item.Sale.type != 'EPR'">
                        {{ item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title }}
                    </span>
                    <span class="font-weight-bold" v-if="['WH','EPR'].includes(item.Sale.type)">
                        {{item.Sale.title}}
                    </span>
                </span>
            </template>
            <template v-slot:item.Sale.salesdate="{item}">
                <span>{{ formatDate(item.Sale.salesdate) }}</span>
            </template>
            <template v-slot:item.Sale.Customer.title="{item}">
                <span>{{ item.Sale.Customer.title }}</span>
            </template>
            <template v-slot:item.Sale.SalesContact.abbreviation="{item}">
                <span v-if="[1,2,5,6,99].includes(item.Sale.salestype_id)">{{ item.Sale.SalesContact.abbreviation }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.Supplier.title="{item}">
                <span v-if="[2,3,6,100].includes(item.Sale.salestype_id)">{{ item.Sale.Supplier.code }}</span>
                <span v-if="[1,5].includes(item.Sale.salestype_id)">{{ $t('message.app') }}</span>
            </template>
            <template v-slot:item.Sale.supplier_pi_no="{item}">
                <span>{{ item.Sale.supplier_pi_no }}</span>
            </template>
            <template v-slot:item.Sale.po="{item}">
                <span v-if="[1,5].includes(item.Sale.salestype_id)">
                    {{ item.Sale.Supplier.code }}
                </span>
                <span v-if="[2].includes(item.Sale.salestype_id)">{{ $t('message.ok').toUpperCase() }}</span>
                <span v-if="[3,6].includes(item.Sale.salestype_id)">
                    <v-icon small color="yellow" v-if="item.Sale.posigned != 1">fiber_manual_record</v-icon>
                    {{ $t('message.ok').toUpperCase() }}
                </span>
            </template>
            <template v-slot:item.Sale.Species.title="{item}">
                <div class="d-flex flex-column">
                    <span v-for="(species, index) in item.Sale.Species.title" :key="index" :class="item.Sale.Species.title.length > 1 ? 'text-no-wrap font-xs' : ''">{{ species }}</span>
                </div>
            </template>
            <template v-slot:item.Sale.Grade.title="{item}">
                <div class="d-flex flex-column">
                    <span v-for="(grade, index) in item.Sale.Grade.title" :key="index" :class="item.Sale.Grade.title.length > 1 ? 'text-no-wrap font-xs' : ''">{{ grade }}</span>
                </div>
            </template>
            <template v-slot:item.Sale.Size.title="{item}">
                <div class="d-flex flex-column">
                    <span v-for="(size, index) in item.Sale.Size.title" :key="index"  :class="item.Sale.Size.title.length > 1 ? 'truncate font-xs' : 'truncate'">{{ size }}</span>
                </div>
            </template>s
            <template v-slot:item.Sale.containers="{item}">
                <div class="text-center" v-if="item.Sale.salestype_id != 99">
                    <span class="text-no-wrap" v-if="showContainers(item.Sale.item_id)">{{ item.Sale.containers != '' ? item.Sale.containers : $t('message.na')  }}</span>
                </div>
                <div class="text-center" v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.qty="{item}">
                <span class="text-no-wrap">{{ formatThisNumber(item.Sale.qty, '0,0.00') + " " + (item.Sale.Measurement.title != null ? item.Sale.Measurement.title : '') }}</span>
            </template>
            <template v-slot:item.Sale.price="{item}">
                <div class="d-flex flex-column">
                    <template v-for="(price,index) in item.Sale.price">
                        <span :class="item.Sale.price.length > 1 ? 'text-no-wrap font-xs' : 'text-no-wrap'" v-if="price >= 10" :key="index">{{ formatThisNumber(price, '0,0') + ' ' + item.Sale.Contract.currency + (item.Sale.Measurement.title != null ? '/' + item.Sale.Measurement.title : '') }}</span>
                        <span :class="item.Sale.price.length > 1 ? 'text-no-wrap font-xs' : 'text-no-wrap'" v-else :key="index">{{ formatThisNumber(price, '0,0.0') + ' ' + item.Sale.Contract.currency + (item.Sale.Measurement.title != null ? '/' + item.Sale.Measurement.title : '') }}</span>
                    </template>
                </div>
            </template>
            <template v-slot:item.Sale.cost="{item}">
                <span class="text-no-wrap">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.cost, '0,0') }}</span>
            </template>
            <template v-slot:item.Sale.value="{item}">
                <span class="text-no-wrap">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.value, '0,0') }}</span>
            </template>
            <template v-slot:item.Sale.shipweekdate="{item}">
                <span class="text-no-wrap">{{ formatDate(item.Sale.shipweekdate) }}</span>
            </template>
            <template v-slot:item.Sale.shipment_status="{ item }">
                <span v-if="item.Sale.salestype_id != 99">{{ item.Sale.shipment_status }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.etsdate="{item}">
                <span class="text-no-wrap" v-if="item.Sale.etsdate != null ">{{ formatDate(item.Sale.etsdate) }}</span>
            </template>
            <template v-slot:item.Sale.etddate="{item}">
                <span class="text-no-wrap" v-if="item.Sale.etddate != null ">{{ formatDate(item.Sale.etddate) }}</span>
            </template>
            <template v-slot:item.Sale.etadate="{item}">
                <span class="text-no-wrap" v-if="item.Sale.etadate != null ">{{ formatDate(item.Sale.etadate) }}</span>
            </template>
            <template v-slot:item.Sale.internal_remarks="{item}">
                <span v-if="item.Sale.internal_remarks != null ">{{ item.Sale.internal_remarks }}</span>
            </template>
            <template v-slot:item.Sale.Paymentterm.title="{item}">
                <span v-if="item.Sale.Paymentterm.title != null ">{{ item.Sale.Paymentterm.title }}</span>
            </template>
            <template v-slot:item.Sale.Supplierpayment.title="{item}">
                <span v-if="item.Sale.Supplierpayment.title != null ">{{ item.Sale.Supplierpayment.title }}</span>
            </template>
            <template v-slot:item.Sale.Debitnote.title="{item}">
                <span v-if="item.Sale.Debitnote.title != null ">{{ item.Sale.Debitnote.title }}</span>
            </template>
            <template v-slot:item.Sale.margin="{item}">
                <span class="text-no-wrap">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.margin, '0,0') }}</span>
            </template>
            <template v-slot:item.Sale.margin_pct="{item}">
                <span class="text-no-wrap" v-if="item.Sale.salestype_id == 2">{{ formatThisNumber(item.Sale.margin_pct, '0.0') }}</span>
<!--                <span class="text-no-wrap" v-if="[1,5].includes(item.Sale.salestype_id)">{{ formatThisNumber((item.Sale.margin / item.Sale.value * 100),'0.0') }}</span>-->
                <span class="text-no-wrap" v-if="[1,5].includes(item.Sale.salestype_id)">{{ formatThisNumber((item.Sale.margin_pct),'0.0') }}</span>
                <span class="text-no-wrap" v-if="[3,6].includes(item.Sale.salestype_id)">{{ $t('message.na') }}</span>
            </template>
        </v-data-table>
        <SimpleAlert
            :dialog.sync="dialogs.error"
            :alert_message.sync="dialogs.error_message"
            @dialog-closed="dialogClosed"
        >
        </SimpleAlert>
        <ShippingDocumentsList
            :main-document="shippingDocumentsTitle"
            :open-dialog="shippingDocumentsDialog"
            :shipping-documents="shippingDocuments"
            @dialog-closed="closeShippingDocumentsDialog()"
        ></ShippingDocumentsList>
        <ShippingInfoSummary
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoDialog"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoDialog()"
        ></ShippingInfoSummary>
<!--        <ShippingInfoAndDocuments-->
<!--            :main-document="shippingInfoTitle"-->
<!--            :open-dialog="shippingInfoAndDocumentsDialog"-->
<!--            :shipping-documents="shippingDocuments"-->
<!--            :shipment-id="shippingInfoId"-->
<!--            @dialog-closed="closeShippingInfoAndDocumentsDialog()"-->
<!--        ></ShippingInfoAndDocuments>-->
        <ShippingInfoAndDocumentsV3
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoAndDocumentsDialog"
            :shipping-documents="shippingDocuments"
            :eutr-documents="eutrDocuments"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoAndDocumentsDialog()"
        />
        <NonShippingDocumentsList
            :main-document="nonShippingDocumentsTitle"
            :open-dialog="nonShippingDocumentsDialog"
            :non-shipping-documents="nonShippingDocuments"
            @dialog-closed="closeNonShippingDocumentsDialog()"
        />
    </div>
</template>

<script>
// import SimpleAlert from "Components/Appic/SimpleAlert";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import {formatDate,formatDateShort,  numberFormat, removeByAttr} from "Helpers/helpers";
import {mapActions, mapGetters} from 'vuex'
// import ExportTable from "Components/Appic/ExportTable";
import _ from 'lodash';
// import ShippingDocumentsList from "Components/Appic/ShippingDocumentsList";
import {api} from "Api";
import { v4 as uuidv4 } from 'uuid';
// import ShippingInfoSummary from "Components/Appic/ShippingInfoSummary";

const ExportTableAppicReportV2 = () => import("Components/Appic/ExportTableAppicReportV2");
const ShippingDocumentsList = () => import("Components/Appic/ShippingDocumentsList");
const ShippingInfoAndDocuments = () => import("Components/Appic/ShippingInfoAndDocuments");
const ShippingInfoSummary = () => import("Components/Appic/ShippingInfoSummary");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");
const NonShippingDocumentsList = () => import("Components/Appic/NonShippingDocumentsList");
const PrintContractButton = () => import("Components/Appic/Printing/PrintContractButton");
const PrintPurchaseOrderButton = () => import("Components/Appic/Printing/PrintPurchaseOrderButton");

const ShippingInfoAndDocumentsV3 = () => import("Components/Appic/ShippingInfoAndDocumentsV3");
// const ShippingInfoAndDocuments = () => import("Components/Appic/ShippingInfoAndDocuments);


export default {
name: "AppicReportListingV2",
    components: {
        NonShippingDocumentsList,
        PrintContractButton,
        PrintPurchaseOrderButton,
        ShippingInfoAndDocuments,
        ShippingInfoAndDocumentsV3,
        ShippingInfoSummary,
        ShippingDocumentsList,
        ExportTableAppicReportV2,
        SimpleAlert
    },
    data () {
        return {
            certifications: [
                {Certification: {id: 'fsc', title: 'FSC'}},
                {Certification: {id: 'pefc', title: 'PEFC'}},
            ],
            dateFields: [
                {Datefield: {id: 'shipweekdate', text: this.$t('message.osd')}},
                {Datefield: {id: 'etddate', text: this.$t('message.etd')}},
                {Datefield: {id: 'etadate', text: this.$t('message.eta')}},
                {Datefield: {id: 'lclsdate', text: this.$t('message.lcsd')}},
                {Datefield: {id: 'lcedate', text: this.$t('message.lced')}},
                // {Datefield: {id: 'etdweek', text: this.$t('message.etdweek')}},
            ],
            defaultFilterOptions: {
                agents: [],
                customers: [],
                dateField: 'shipweekdate',
                destinations: [],
                grades: [],
                gradeGroups: [],
                marketsegments: [],
                monthFrom: 1,
                monthTo: 12,
                productgroups: [],
                salescolleagues: [],
                salescolleague_include_suspended: false,
                shipped: 'A',
                sizes: [],
                species: [],
                status: [
                   {Salestatus: {text: this.$t('message.active'), id: 1}}
                ],
                // status: 1,
                suppliers: [],
                // types: {Salestype: {text: this.$t('message.allWoWhSales'), id: 'allcontracts'}},
                thickness: [],
                types: [],
                yearFrom: 0,
                yearTo: 0,
            },
            defaultFilterAll: {
                agents: {
                    selectedAll: true
                },
                customers: {
                    selectedAll: true
                },
                destinations: {
                    selectedAll: true
                },
                grades: {
                    selectedAll: true
                },
                gradeGroups: {
                    selectedAll: true
                },
                marketsegments: {
                    selectedAll: true
                },
                productgroups: {
                    selectedAll: true
                },
                salescolleagues: {
                    selectedAll: true
                },
                sizes: {
                    selectedAll: true
                },
                species: {
                    selectedAll: true
                },
                status: {
                    selectedAll: false
                },
                suppliers: {
                    selectedAll: true
                },
                thickness: {
                    selectedAll: true
                },
                types: {
                    selectedAll: false
                }
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            eutrDocuments: [],
            export: {
                conditions: null,
                params: null
            },
            filterOptions: {
                agents: [],
                certifications: [],
                customers: [],
                dateField: 'shipweekdate',
                destinations: [],
                grades: [],
                gradeGroups: [],
                marketsegments: [],
                monthFrom: 1,
                monthTo: 12,
                productgroups: [],
                salescolleagues: [],
                salescolleague_include_suspended: false,
                shipped: 'A',
                sizes: [],
                species: [],
                status: [
                    {Salestatus: {text: this.$t('message.active'), id: 1}}
                ],
                // status: 1,
                suppliers: [],
                // types: {Salestype: {text: this.$t('message.allWoWhSales'), id: 'allcontracts'}},
                thickness: [],
                types: [],
                yearFrom: 0,
                yearTo: 0,
            },
            filterAll: {
                agents: {
                    selectedAll: true
                },
                certifications: {
                    selectedAll: true
                },
                customers: {
                    selectedAll: true
                },
                destinations: {
                    selectedAll: true
                },
                grades: {
                    selectedAll: true
                },
                gradeGroups: {
                    selectedAll: true
                },
                marketsegments: {
                    selectedAll: true
                },
                productgroups: {
                    selectedAll: true
                },
                salescolleagues: {
                    selectedAll: true
                },
                sizes: {
                    selectedAll: true
                },
                species: {
                    selectedAll: true
                },
                status: {
                    selectedAll: false
                },
                suppliers: {
                    selectedAll: true
                },
                thickness: {
                    selectedAll: true
                },
                types: {
                    selectedAll: false
                }
            },
            fixedHeader: false,
            hideDefaultFooter: false,
            loading: {
                clear: false,
                fetch: false,
                items: false,
                filter: {
                    agents: false,
                    certifications: null,
                    customers: false,
                    destinations: false,
                    grades: false,
                    gradeGroups: false,
                    marketsegments: false,
                    productgroups: false,
                    salescolleagues: false,
                    shipmentStatus: false,
                    sizes: false,
                    species: false,
                    status: false,
                    suppliers: false,
                    thickness: false,
                    types: false
                },
                filterResults: false
            },
            nonShippingDocuments: [],
            nonShippingDocumentsDialog: false,
            nonShippingDocumentsTitle: '',
            options: {
                shipmentStatus: [
                    {value: 'A', text: this.$t('message.all')},
                    {value: 'S', text: this.$t('message.shipped')},
                    {value: 'U', text: this.$t('message.unshipped')}
                ]
            },
            rules: {
                agents: [
                    v => (this.filterAll.agents.selectedAll == false && this.filterOptions.agents.length > 0) || this.filterAll.agents.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                certifications: [
                    v => (this.filterAll.certifications.selectedAll == false && this.filterOptions.certifications.length > 0) || this.filterAll.certifications.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                buyers: [
                    v => (this.filterAll.customers.selectedAll == false && this.filterOptions.customers.length > 0) || this.filterAll.customers.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                destinations: [
                    v => (this.filterAll.destinations.selectedAll == false && this.filterOptions.destinations.length > 0) || this.filterAll.destinations.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                grades: [
                    v => (this.filterAll.grades.selectedAll == false && this.filterOptions.grades.length > 0) || this.filterAll.grades.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                gradeGroups: [
                    v => (this.filterAll.gradeGroups.selectedAll == false && this.filterOptions.gradeGroups.length > 0) || this.filterAll.gradeGroups.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                marketsegments: [
                    v => (this.filterAll.marketsegments.selectedAll == false && this.filterOptions.marketsegments.length > 0) || this.filterAll.marketsegments.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                productgroups: [
                    v => (this.filterAll.productgroups.selectedAll == false && this.filterOptions.productgroups.length > 0) || this.filterAll.productgroups.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                sizes: [
                    v => (this.filterAll.sizes.selectedAll == false && this.filterOptions.sizes.length > 0) || this.filterAll.sizes.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                species: [
                    v => (this.filterAll.species.selectedAll == false && this.filterOptions.species.length > 0) || this.filterAll.species.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                status: [
                    v => (this.filterAll.status.selectedAll == false && this.filterOptions.status.length > 0) || this.filterAll.status.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                // status: v => !!v || this.$t('message.required'),
                salescolleagues: [
                    v => (this.filterAll.salescolleagues.selectedAll == false && this.filterOptions.salescolleagues.length > 0) || this.filterAll.salescolleagues.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                suppliers: [
                    v => (this.filterAll.suppliers.selectedAll == false && this.filterOptions.suppliers.length > 0) || this.filterAll.suppliers.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                thickness: [
                    v => (this.filterAll.thickness.selectedAll == false && this.filterOptions.thickness.length > 0) || this.filterAll.thickness.selectedAll || this.$t('message.selectAtLeastOneOption')
                ],
                yearFrom: [true],
                yearTo: [true]
            },
            Sales: [],
            salesStatuses: [
                {Salestatus: {text: this.$t('message.active'), id: 1, iconColor: null}},
                {Salestatus: {text: this.$t('message.completed'), id: 2, iconColor: null}},
                {Salestatus: {text: this.$t('message.cancelled'), id: 10, iconColor: null}},
                {Salestatus: {text: this.$t('message.unsignedContract'), id: 991, iconColor: 'pink lighten-3'}}, //added 2021-11-01
                {Salestatus: {text: this.$t('message.unsignedPo'), id: 992, iconColor: 'blue darken-4'}}, //added 2021-11-01
                {Salestatus: {text: this.$t('message.unsignedWhPo'), id: 993, iconColor: 'yellow lighten-3'}}, //added 2021-11-01
                {Salestatus: {text: this.$t('message.hideFromReport'), id: 30, iconColor: null}}
            ],
            // salesTypes: [
            //     // {Salestype: {text: this.$t('message.all'), id: 'all'}},
            //     // {Salestype: {text: this.$t('message.allWoWhSales'), id: 'allcontracts'}},
            //     {Salestype: {text: this.$t('message.directSales'), id: 1}},
            //     {Salestype: {text: this.$t('message.servicedSales'), id: 5}},
            //     {Salestype: {text: this.$t('message.warehouseSales'), id: 99}},
            //     {Salestype: {text: this.$t('message.commissionSales'), id: 2}},
            //     {Salestype: {text: this.$t('message.poForExport'), id: 100}},
            //     {Salestype: {text: this.$t('message.poForStock'), id: 3}}
            // ],
            searchTerm: null,
            shippingDocuments: [],
            shippingDocumentsDialog: false,
            shippingDocumentsTitle: '',
            shippingInfoAndDocumentsDialog: false,
            shippingInfoDialog: false,
            shippingInfoId: null,
            shippingInfoTitle: '',
            sorting: {},
            tableHeight: '500',
            tableOptions: {
                page: 1,
                itemsPerPage: 50
            },
            totalSales: 0,
            validForm: true
        }
    },
    computed: {
        // ...mapMultiRowFields('report',{
        //     Sales: 'current.Appic.Sales'
        // }),
        ...mapFields('report',{
            exportAppicReportParams: 'exportAppicReportParams',
            salesAgents: 'current.Appic.salesAgents',
            salesCustomers: 'current.Appic.salesCustomers',
            salesDestinations: 'current.Appic.salesDestinations',
            salesGrades: 'current.Appic.salesGrades',
            salesGradeGroups: 'current.Appic.salesGradeGroups',
            salesMarketsegments: 'current.Appic.salesMarketsegments',
            salesProductgroups: 'current.Appic.salesProductgroups',
            salesSalescolleagues: 'current.Appic.salesSalescolleagues',
            salesSizes: 'current.Appic.salesSizes',
            salesSpecies: 'current.Appic.salesSpecies',
            salesSuppliers: 'current.Appic.salesSuppliers',
            salesThickness: 'current.Appic.salesThickness',
            scrollingList: 'current.Appic.scrollingList',
            // totalSales: 'current.Appic.totalSales'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('document', {
            allDocuments: 'allDocuments',
        }),
        ...mapGetters([
            'currencies',
            'incoterms',
            'months',
            'uofm'
        ]),
        ...mapGetters('user',{
            User: 'User'
        }),
        headers () {
            let headers = [
                {
                    id: 0,
                    text: this.$t('message.actions'),
                    value: 'Sale.document_id',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 1,
                    text: this.$t('message.date'),
                    value: 'Sale.salesdate',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 2,
                    text: this.$t('message.type'),
                    value: 'Sale.type',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 3,
                    text: this.$t('message.number'),
                    value: 'Sale.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'Sale.Customer.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 5,
                    text: this.$t('message.sc'),
                    value: 'Sale.SalesContact.abbreviation',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 6,
                    text: this.$t('message.spl'),
                    value: 'Sale.Supplier.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 7,
                    text: this.$t('message.splProfomaInvoice'),
                    value: 'Sale.supplier_pi_no',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 8,
                    text: this.$t('message.po'),
                    value: 'Sale.po',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 9,
                    text: this.$t('message.product'),
                    value: 'Sale.Product.code',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 10,
                    text: this.$t('message.species'),
                    value: 'Sale.Species.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 11,
                    text: this.$t('message.grade'),
                    value: 'Sale.Grade.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 12,
                    text: this.$t('message.size'),
                    value: 'Sale.Size.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 13,
                    text: this.$t('message.container'),
                    value: 'Sale.containers',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 14,
                    text: this.$t('message.qty'),
                    value: 'Sale.qty',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-1 pl-1 font-sm text-right sticky-header'
                },
                {
                    id: 15,
                    text: this.$t('message.price'),
                    value: 'Sale.price',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-1 pl-1 font-sm text-right sticky-header'
                },
                {
                    id: 16,
                    text: this.$t('message.cost'),
                    value: 'Sale.cost',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-1 pl-1 font-sm text-right sticky-header'
                },
                {
                    id: 17,
                    text: this.$t('message.value'),
                    value: 'Sale.value',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-1 pl-1 font-sm text-right sticky-header'
                },
                {
                    id: 18,
                    text: this.$t('message.osd'),
                    value: 'Sale.shipweekdate',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 19,
                    text: this.$t('message.shpStatus'),
                    value: 'Sale.shipment_status',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 20,
                    text: this.$t('message.ets'),
                    value: 'Sale.etsdate',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 21,
                    text: this.$t('message.etd'),
                    value: 'Sale.etddate',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 22,
                    text: this.$t('message.eta'),
                    value: 'Sale.etadate',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                },
                {
                    id: 23,
                    text: this.$t('message.paymentBuyer'),
                    value: 'Sale.Paymentterm.title',
                    searchable: true,
                    sortable: true,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct sticky-header'
                }
            ]
            if(!['ROLE_SALES','ROLES_SALES_ADMIN'].includes(this.User.role)) {
                headers.push(
                    {
                        id: 23,
                        text: this.$t('message.paymentSupplier'),
                        value: 'Sale.Supplierpayment.title',
                        searchable: true,
                        sortable: true,
                        class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct sticky-header'
                    }
                )
            }

            headers.push(
                {
                    id: 23,
                    text: this.$t('message.intRmrks'),
                    value: 'Sale.internal_remarks',
                    searchable: true,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                }
            )
            headers.push(
                {
                    id: 24,
                    text: this.$t('message.dn'),
                    value: 'Sale.Debitnote.title',
                    searchable: true,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left sticky-header'
                }
            )
            headers.push(
                {
                    id: 25,
                    text: this.$t('message.margin'),
                    value: 'Sale.margin',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-right sticky-header'
                }
            )
            headers.push(
                {
                    id: 26,
                    text: this.$t('message.mgPct'),
                    value: 'Sale.margin_pct',
                    searchable: false,
                    sortable: false,
                    class: 'grey lighten-3 py-0 pr-0 pl-1 font-sm text-center sticky-header'
                }
            )
            return headers
        },
        salesTypes () {
            let types = [
                {Salestype: {text: this.$t('message.directSales'), id: 1}},
                {Salestype: {text: this.$t('message.servicedSales'), id: 5}},
                {Salestype: {text: this.$t('message.warehouseSales'), id: 99}},
                {Salestype: {text: this.$t('message.commissionSales'), id: 2}}
            ]
            if(this.$can('access','Eprs')){
                types.push(
                    {Salestype: {text: this.$t('message.poForExport'), id: 100}}
                )
            }
            types.push({Salestype: {text: this.$t('message.poForStock'), id: 3}})
            return types
        },
        searchFields() {
            return this.headers.filter((header)=>header.searchable === true)
        },
        selectedSomeAgents () {
            return this.filterOptions.agents.length > 0
        },
        selectedSomeCertifications () {
            return this.filterOptions.certifications.length > 0
        },
        selectedSomeBuyers () {
            return this.filterOptions.customers.length > 0
        },
        selectedSomeDestinations () {
            return this.filterOptions.destinations.length > 0
        },
        selectedSomeGrades () {
            return this.filterOptions.grades.length > 0
        },
        selectedSomeGradeGroups () {
            return this.filterOptions.gradeGroups.length > 0
        },
        selectedSomeMarketSegments () {
            return this.filterOptions.marketsegments.length > 0
        },
        selectedSomeProductGroups () {
            return this.filterOptions.productgroups.length > 0
        },
        selectedSomeSalescolleagues () {
            return this.filterOptions.salescolleagues.length > 0
        },
        selectedSomeSizes () {
            return this.filterOptions.sizes.length > 0
        },
        selectedSomeSpecies () {
            return this.filterOptions.species.length > 0
        },
        selectedSomeStatus () {
            return this.filterOptions.status.length > 0
        },
        selectedSomeSuppliers () {
            return this.filterOptions.suppliers.length > 0
        },
        selectedSomeThickness () {
            return this.filterOptions.thickness.length > 0
        },
        selectedSomeTypes () {
            return this.filterOptions.types.length > 0
        },
        filterAgentsIcon () {
            if (this.filterAll.agents.selectedAll) return 'mdi-close-box'
            if (this.filterAll.agents.selectedAll == false && this.selectedSomeAgents) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterCertificationsIcon () {
            if (this.filterAll.certifications.selectedAll) return 'mdi-close-box'
            if (this.filterAll.certifications.selectedAll == false && this.selectedSomeCertifications) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterCustomersIcon () {
            if (this.filterAll.customers.selectedAll) return 'mdi-close-box'
            if (this.filterAll.customers.selectedAll == false && this.selectedSomeBuyers) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterDestinationsIcon () {
            if (this.filterAll.destinations.selectedAll) return 'mdi-close-box'
            if (this.filterAll.destinations.selectedAll == false && this.selectedSomeDestinations) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterGradesIcon () {
            if (this.filterAll.grades.selectedAll) return 'mdi-close-box'
            if (this.filterAll.grades.selectedAll == false && this.selectedSomeGrades) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterGradeGroupsIcon () {
            if (this.filterAll.gradeGroups.selectedAll) return 'mdi-close-box'
            if (this.filterAll.gradeGroups.selectedAll == false && this.selectedSomeGradeGroups) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterMarketSegmentsIcon () {
            if (this.filterAll.marketsegments.selectedAll) return 'mdi-close-box'
            if (this.filterAll.marketsegments.selectedAll == false && this.selectedSomeMarketSegments) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterProductGroupsIcon () {
            if (this.filterAll.productgroups.selectedAll) return 'mdi-close-box'
            if (this.filterAll.productgroups.selectedAll == false && this.selectedSomeProductGroups) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterSalescolleaguesIcon () {
            if (this.filterAll.salescolleagues.selectedAll) return 'mdi-close-box'
            if (this.filterAll.salescolleagues.selectedAll == false && this.selectedSomeSalescolleagues) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterSizesIcon () {
            if (this.filterAll.sizes.selectedAll) return 'mdi-close-box'
            if (this.filterAll.sizes.selectedAll == false && this.selectedSomeSizes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterSpeciesIcon () {
            if (this.filterAll.species.selectedAll) return 'mdi-close-box'
            if (this.filterAll.species.selectedAll == false && this.selectedSomeSpecies) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterStatusIcon () {
            if (this.filterAll.status.selectedAll) return 'mdi-close-box'
            if (this.filterAll.status.selectedAll == false && this.selectedSomeStatus) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterSuppliersIcon () {
            if (this.filterAll.suppliers.selectedAll) return 'mdi-close-box'
            if (this.filterAll.suppliers.selectedAll == false && this.selectedSomeSuppliers) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterThicknessIcon () {
            if (this.filterAll.thickness.selectedAll) return 'mdi-close-box'
            if (this.filterAll.thickness.selectedAll == false && this.selectedSomeThickness) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterTypeIcon () {
            if (this.filterAll.types.selectedAll) return 'mdi-close-box'
            if (this.filterAll.types.selectedAll == false && this.selectedSomeTypes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        years() {
            let array = [{Year: {text: "All", id: 0}}]
            const currentMonth = new Date().getMonth()
            let currentYear = new Date().getFullYear()
            if(currentMonth > 9) {
                currentYear = currentYear + 1
            }
            let startYear = currentYear - 5
            while(currentYear > startYear){
                array.push({Year: {text: currentYear.toString(), id: currentYear}})
                currentYear = currentYear - 1
            }
            return array
        },
        eutrFiles() {
            let files = [];
            files = this.allDocuments.ShippingEutrDocuments
            return files
        },
    },
    methods: {
        ...mapActions('document',{
            searchAssetDocument: 'searchAssetDocument'
        }),
        ...mapActions('report',{
            getAllSales: 'getAllSales',
            getAllSalesV2: 'getAllSalesV2',
            getSalesFiltersOptions: 'getSalesFiltersOptions',
            resetSalesFilterOptions: 'resetSalesFilterOptions'
        }),
        addToRemoveFromFilterOptions (filter, item) {
            this.$nextTick(() => {
                switch(filter){
                    case 'agent':
                        this.filterAll.agents.selectedAll = false
                        if(this.filterOptions.agents.find(opt => opt.Agent.id == item.Agent.id)){
                            removeByAttr(this.filterOptions.agents, 'Agent','id',item.Agent.id)
                            if(this.filterOptions.agents.length == 0) this.filterAll.agents.selectedAll = true
                        } else {
                            this.filterOptions.agents.push(item)
                        }
                        break;
                    case 'certification':
                        this.filterAll.certifications.selectedAll = false
                        if(this.filterOptions.certifications.find(opt => opt.Certification.id == item.Certification.id)){
                            removeByAttr(this.filterOptions.certifications, 'Certification','id',item.Certification.id)
                            if(this.filterOptions.certifications.length == 0) this.filterAll.certifications.selectedAll = true
                        } else {
                            this.filterOptions.certifications.push(item)
                        }
                        break;
                    case 'customer':
                        this.filterAll.customers.selectedAll = false
                        if(this.filterOptions.customers.find(opt => opt.Customer.id == item.Customer.id)){
                            removeByAttr(this.filterOptions.customers, 'Customer','id',item.Customer.id)
                            if(this.filterOptions.customers.length == 0) this.filterAll.customers.selectedAll = true
                        } else {
                            this.filterOptions.customers.push(item)
                        }
                        break;
                    case 'destination':
                        this.filterAll.destinations.selectedAll = false
                        if(this.filterOptions.destinations.find(opt => opt.Destination.id == item.Destination.id)){
                            removeByAttr(this.filterOptions.destinations, 'Destination','id',item.Destination.id)
                            if(this.filterOptions.destinations.length == 0) this.filterAll.destinations.selectedAll = true
                        } else {
                            this.filterOptions.destinations.push(item)
                        }
                        break;
                    case 'grade':
                        this.filterAll.grades.selectedAll = false
                        if(this.filterOptions.grades.find(opt => opt.Grade.id == item.Grade.id)){
                            removeByAttr(this.filterOptions.grades, 'Grade','id',item.Grade.id)
                            if(this.filterOptions.grades.length == 0) this.filterAll.grades.selectedAll = true
                        } else {
                            this.filterOptions.grades.push(item)
                        }
                        break;
                    case 'gradeGroup':
                        this.filterAll.gradeGroups.selectedAll = false
                        if(this.filterOptions.gradeGroups.find(opt => opt.GradeGroup.id == item.GradeGroup.id)){
                            removeByAttr(this.filterOptions.gradeGroups, 'GradeGroup','id',item.GradeGroup.id)
                            if(this.filterOptions.gradeGroups.length == 0) this.filterAll.gradeGroups.selectedAll = true
                        } else {
                            this.filterOptions.gradeGroups.push(item)
                        }
                        break;
                    case 'marketsegment':
                        this.filterAll.marketsegments.selectedAll = false
                        if(this.filterOptions.marketsegments.find(opt => opt.Marketsegment.id == item.Marketsegment.id)){
                            removeByAttr(this.filterOptions.marketsegments, 'Marketsegment','id',item.Marketsegment.id)
                            if(this.filterOptions.marketsegments.length == 0) this.filterAll.marketsegments.selectedAll = true
                        } else {
                            this.filterOptions.marketsegments.push(item)
                        }
                        break;
                    case 'productgroup':
                        this.filterAll.productgroups.selectedAll = false
                        if(this.filterOptions.productgroups.find(opt => opt.Productgroup.id == item.Productgroup.id)){
                            removeByAttr(this.filterOptions.productgroups, 'Productgroup','id',item.Productgroup.id)
                            if(this.filterOptions.productgroups.length == 0) this.filterAll.productgroups.selectedAll = true
                        } else {
                            this.filterOptions.productgroups.push(item)
                        }
                        break;
                    case 'salescolleague':
                        this.filterAll.salescolleagues.selectedAll = false
                        if(this.filterOptions.salescolleagues.find(opt => opt.Salescontact.id == item.Salescontact.id)){
                            removeByAttr(this.filterOptions.salescolleagues, 'Salescontact','id',item.Salescontact.id)
                            if(this.filterOptions.salescolleagues.length == 0) this.filterAll.salescolleagues.selectedAll = true
                        } else {
                            this.filterOptions.salescolleagues.push(item)
                        }

                        break;
                    case 'size':
                        this.filterAll.sizes.selectedAll = false
                        if(this.filterOptions.sizes.find(opt => opt.Size.id == item.Size.id)){
                            removeByAttr(this.filterOptions.sizes, 'Size','id',item.Size.id)
                            if(this.filterOptions.sizes.length == 0) this.filterAll.sizes.selectedAll = true
                        } else {
                            this.filterOptions.sizes.push(item)
                        }
                        break;
                    case 'species':
                        this.filterAll.species.selectedAll = false
                        if(this.filterOptions.species.find(opt => opt.Species.id == item.Species.id)){
                            removeByAttr(this.filterOptions.species, 'Species','id',item.Species.id)
                            if(this.filterOptions.species.length == 0) this.filterAll.species.selectedAll = true
                        } else {
                            this.filterOptions.species.push(item)
                        }
                        break;
                    case 'supplier':
                        this.filterAll.suppliers.selectedAll = false
                        if(this.filterOptions.suppliers.find(opt => opt.Supplier.id == item.Supplier.id)){
                            removeByAttr(this.filterOptions.suppliers, 'Supplier','id',item.Supplier.id)
                            if(this.filterOptions.suppliers.length == 0) this.filterAll.suppliers.selectedAll = true
                        } else {
                            this.filterOptions.suppliers.push(item)
                        }
                        break;
                    case 'status':
                        this.filterAll.status.selectedAll = false
                        if(this.filterOptions.status.find(opt => opt.Salestatus.id == item.Salestatus.id)){
                            removeByAttr(this.filterOptions.status, 'Salestatus','id',item.Salestatus.id)
                            if(this.filterOptions.status.length == 0) this.filterAll.status.selectedAll = true
                        } else {
                            this.filterOptions.status.push(item)
                        }
                        break;
                    case 'thickness':
                        this.filterAll.thickness.selectedAll = false
                        if(this.filterOptions.thickness.find(opt => opt.Thickness.id == item.Thickness.id)){
                            removeByAttr(this.filterOptions.thickness, 'Thickness','id',item.Thickness.id)
                            if(this.filterOptions.thickness.length == 0) this.filterAll.thickness.selectedAll = true
                        } else {
                            this.filterOptions.thickness.push(item)
                        }
                        break;
                    case 'type':
                        if(this.filterOptions.types.find(opt => opt.Salestype.id === item.Salestype.id)){
                            removeByAttr(this.filterOptions.types, 'Salestype','id',item.Salestype.id)
                        } else {
                            if(this.filterAll.types.selectedAll === false){
                                this.filterOptions.types.push(item)
                                if(this.filterOptions.types.length === this.salesTypes.length){
                                    this.filterAll.types.selectedAll = true
                                    this.filterOptions.types = []
                                }
                            } else {
                                this.filterAll.types.selectedAll = false
                                if (this.filterOptions.types.length === this.salesTypes.length - 1) {
                                    this.filterOptions.types = []
                                    this.filterOptions.types.push(item)
                                } else {
                                    this.filterOptions.types = []
                                    this.salesTypes.forEach(t => {
                                        if (t.Salestype.id !== item.Salestype.id) {
                                            this.filterOptions.types.push(t)
                                        }
                                    })
                                }
                            }
                        }
                        break;
                }
            })
        },
        checkEutrEudrDocument(docTypeId) {
            const file = this.eutrFiles.find(ef => ef.Document.doctype_id == docTypeId)
            if(file) return true
            return false
        },
        closeNonShippingDocumentsDialog () {
            this.nonShippingDocumentsDialog = false
        },
        closeShippingDocumentsDialog () {
            this.shippingDocumentsDialog = false
        },
        closeShippingInfoAndDocumentsDialog() {
            this.shippingInfoAndDocumentsDialog = false
            this.shippingInfoId = null
            this.shippingInfoTitle = ''
            this.shippingDocuments = []
            this.eutrDocuments = []
        },
        closeShippingInfoDialog() {
            this.shippingInfoDialog = false
            this.shippingInfoId = null
            this.shippingInfoTitle = ''
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatDate,
        formatDateShort,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getUofMFormatPrecision (unit) {
            this.uofm.find(u => u.Measurement.title == unit).Measurement.formatPrecision
        },
        handleResize() {
            // if(this.scrollingList) {
            //     this.tableHeight = window.innerHeight - (430);
            // } else {
            //     this.tableHeight = null
            // }

            this.tableHeight = window.innerHeight - (430);
        },
        loadFilterOptions(filter) {
            let conditions = []
            if(this.filterOptions.yearFrom != null && this.filterOptions.yearFrom > 0){
                conditions.push({field: 'salesYearFrom', value: this.filterOptions.yearFrom})
            }
            if(this.filterOptions.yearTo != null && this.filterOptions.yearTo > 0 && this.filterOptions.yearFrom != this.filterOptions.yearTo){
                conditions.push({field: 'salesYearTo', value: this.filterOptions.yearTo})
            }
            let payload = {
                filter: filter,
                conditions: conditions
            }
            this.loading.filter[filter] = true
            this.getSalesFiltersOptions(payload)
                .then(() => {
                    this.loading.filter[filter] = false
                })
                .catch(() => {
                    this.loading.filter[filter] = false
                })
        },
        loadSales () {
            let valid = this.$refs.filterForm.validate()
            if( valid ) {
                this.loading.items = true
                this.loading.fetch = true
                let payload = {
                    // tableOptions: this.tableOptions,
                    filterOptions: this.filterOptions,
                    // searchTerm: this.searchTerm
                }
                this.getAllSalesV2(payload)
                    .then((tableData) => {
                        this.Sales = tableData.rows
                        let i = 1
                        this.Sales.forEach( sale => {
                            sale.lineNumber = i
                            i++
                        })
                        this.totalSales = tableData.totalRows
                        this.loading.items = false
                        this.loading.fetch = false
                    })
                    .catch(() => {
                        this.loading.items = false
                        this.loading.fetch = false;
                    })
                    .finally(() => {
                        //Reset to page 1
                        this.tableOptions.page = 1
                    })
            }
        },
        newContract(){
            let tab = window.open('/v1/contracts/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        openNonShippingDocumentsDialog (documentId, documentTitle) {
            this.loading.items = true
            let promise = new Promise((resolve, reject) => {
                api
                    .get("/other-contract-documents/" + documentId)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
            promise
                .then( (data) => {
                    this.nonShippingDocuments = []
                    this.nonShippingDocumentsTitle = ''
                    this.nonShippingDocuments = data
                    this.nonShippingDocumentsTitle = documentTitle
                })
                .catch(() => {
                    this.loading.items = false;
                })
                .then(() => {
                    let self = this
                    setTimeout(function(){
                        self.loading.items = false;
                        self.nonShippingDocumentsDialog = true;
                    }, 100)
                })
        },
        openShippingDocumentsDialog ( documentId, documentTitle) {
            this.loading.items = true
            let promise = new Promise((resolve, reject) => {
                api
                    .get("/shipping-documents/" + documentId)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
            promise
                .then( (data) => {
                    this.shippingDocuments = [];
                    this.shippingDocumentsTitle = '';
                    this.shippingDocuments = data
                    this.shippingDocumentsTitle = documentTitle
                })
                .catch(() => {
                    this.loading.items = false;
                })
                .then(() => {
                    let self = this
                    setTimeout(function(){
                        self.loading.items = false
                        self.shippingDocumentsDialog = true
                    }, 100)
                })
        },
        openShippingInfoAndDocumentsDialog(documentId, documentTitle) {
            this.loading.items = true
            this.shippingDocuments = []
            this.eutrDocuments = []
            let promise = new Promise((resolve, reject) => {
                api
                    .get("/shipping-documents/" + documentId)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
            promise
                .then( (data) => {
                    this.loading.items = false;
                    data.forEach( d => {
                        if(this.checkEutrEudrDocument(d.Document.doctype_id)){
                            this.eutrDocuments.push(d)
                        } else {
                            this.shippingDocuments.push(d)
                        }
                    })
                    this.shippingInfoTitle = documentTitle
                    this.shippingInfoId = documentId
                    this.shippingInfoAndDocumentsDialog = true
                })
                .catch(() => {
                    this.loading.items = false;
                })
        },
        openShippingInfoDialog( documentId, documentTitle ) {
            this.shippingInfoTitle = documentTitle
            this.shippingInfoId = documentId
            this.shippingInfoDialog = true
        },
        resetFilters () {
            this.loading.clear = true
            this.filterOptions = _.cloneDeep(this.defaultFilterOptions)
            this.filterAll = _.cloneDeep(this.defaultFilterAll)
            this.loading.clear = false
        },
        searchWithDebounce () {
            //TODO create search with debounce
        },
        showContainers (item_id){
            let show = true
            let document_id = null
            this.Sales.forEach(sale => {
                if (item_id == sale.Sale.item_id) {
                    if(sale.Sale.document_id == document_id) show = false
                    return
                }
                document_id = sale.Sale.document_id
            })
            return show
        },
        styleTheRow (item) {
            let rowClass = null
            switch( item.Sale.rowClass ){
                case 'first-row':
                    rowClass = 'border-top'
                    if(item.lineNumber % this.tableOptions.itemsPerPage == 0){
                        rowClass = 'border-top and border-bottom'
                    }
                    if(item.lineNumber == this.Sales.length){
                        rowClass = 'border-top and border-bottom'
                    }
                    break
                case 'last-row':
                    rowClass = 'border-bottom'
                    break
                case 'single-row':
                    rowClass = 'border-top-and-bottom-and-left-and-right'
                    break
                default:
                    rowClass = 'border-left-and-right'
                    if(item.lineNumber % this.tableOptions.itemsPerPage == 0){
                        rowClass = 'border-bottom'
                    }
                    if(item.lineNumber == this.Sales.length){
                        rowClass = 'border-bottom'
                    }
                    break
            }
            return rowClass
        },
        toggleFilterAll(filter) {
            this.$nextTick(() => {
                switch(filter){
                    case 'agents':
                        this.filterOptions.agents = []
                        this.filterAll.agents.selectedAll = !this.filterAll.agents.selectedAll
                        break;
                    case 'certifications':
                        this.filterOptions.certifications = []
                        this.filterAll.certifications.selectedAll = !this.filterAll.certifications.selectedAll
                        break;
                    case 'customers':
                        this.filterOptions.customers = []
                        this.filterAll.customers.selectedAll = !this.filterAll.customers.selectedAll
                        break;
                    case 'destinations':
                        this.filterOptions.destinations = []
                        this.filterAll.destinations.selectedAll = !this.filterAll.destinations.selectedAll
                        break;
                    case 'grades':
                        this.filterOptions.grades = []
                        this.filterAll.grades.selectedAll = !this.filterAll.grades.selectedAll
                        break;
                    case 'gradeGroups':
                        this.filterOptions.gradeGroups = []
                        this.filterAll.gradeGroups.selectedAll = !this.filterAll.gradeGroups.selectedAll
                        break;
                    case 'marketsegments':
                        this.filterOptions.marketsegments = []
                        this.filterAll.marketsegments.selectedAll = !this.filterAll.marketsegments.selectedAll
                        break;
                    case 'productgroups':
                        this.filterOptions.productgroups = []
                        this.filterAll.productgroups.selectedAll = !this.filterAll.productgroups.selectedAll
                        break;
                    case 'salescolleagues':
                        this.filterOptions.salescolleagues = []
                        this.filterAll.salescolleagues.selectedAll = !this.filterAll.salescolleagues.selectedAll
                        break;
                    case 'species':
                        this.filterOptions.species = []
                        this.filterAll.species.selectedAll = !this.filterAll.species.selectedAll
                        break;
                    case 'sizes':
                        this.filterOptions.sizes = []
                        this.filterAll.sizes.selectedAll = !this.filterAll.sizes.selectedAll
                        break;
                    case 'suppliers':
                        this.filterOptions.suppliers = []
                        this.filterAll.suppliers.selectedAll = !this.filterAll.suppliers.selectedAll
                        break;
                    case 'status':
                        this.filterOptions.status = []
                        this.filterAll.status.selectedAll = !this.filterAll.status.selectedAll
                        break;
                    case 'thickness':
                        this.filterOptions.thickness = []
                        this.filterAll.thickness.selectedAll = !this.filterAll.thickness.selectedAll
                        break;
                    case 'types':
                        this.filterOptions.types = []
                        this.filterAll.types.selectedAll = !this.filterAll.types.selectedAll
                        break;
                }
            })
        },
        updateContract (val) {
            let tab = window.open('/v1/contracts/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateContractPayments(val) {
            let tab = window.open('/v1/payments/update/contracts/' + val,'_blank')
            tab.name = 'contractPayments_' + val
            this.openedTabs.push(tab.name)
        },
        updateDebitNote(val) {
            let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
            tab.name = 'debitNote_' + val
            this.openedTabs.push(tab.name)
        },
        updateInvoice(val) {
            let tab = window.open('/v1/invoices/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateEpr (val) {
            let tab = window.open('/v1/eprs/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateLca(val) {
            let tab = window.open('/v1/lcas/update/' + val,'_blank')
            tab.name = 'lca_' + val
            this.openedTabs.push(tab.name)
        },
        updatePackingList(val) {
            let tab = window.open('/v1/packinglists/update/' + val,'_blank')
            tab.name = 'packingList_' + val
            this.openedTabs.push(tab.name)
        },
        updatePurchaseOrder(val) {
            let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
            tab.name = 'purchaseOrder_' + val
            this.openedTabs.push(tab.name)
        },
        updateSalesOrder (val) {
            let tab = window.open('/v1/salesorders/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateScroll() {
            console.log(window.scrollY)
        },
        updateShipment (val) {
            let tab = window.open('/v1/shipments/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateShippingInstruction(val){
            let tab = window.open('/v1/sdas/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewEprPoPdf(epr) {
            let document = encodeURIComponent(epr.Sale.title)
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/eprs/po/print/'
                + epr.Sale.document_id
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                // + '&fileName=' + contract.Sale.title + '.pdf'
                , "_blank")
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewGrossMargin(item) {
            //check if gross margin calculation exists
            if( item.Sale.GrossMargin.id == null ) {
                this.dialogs.error_message = this.$t('message.grossMarginNotAvailableYet')
                this.dialogs.error = true
            } else {
                let title = ''
                let source = 'invoice'
                if(item.Sale.salestype_id != 99){
                    title = item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title
                } else {
                    title = item.Sale.So.title
                    source = 'whsales_invoice'
                }
                let document = encodeURIComponent('GM ' + title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/gross-margins/print/'
                    + source
                    + '/'
                    + item.Sale.GrossMargin.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
            }
        },
        viewPdf (contract) {
            let document = encodeURIComponent(contract.Sale.document_version != null ? [contract.Sale.Contract.title, contract.Sale.Contract.revision_no , contract.Sale.Contract.partial_no].filter(Boolean).join('-') : contract.Sale.Contract.title)
            if([1,5].includes(contract.Sale.salestype_id)) {
                if(contract.Sale.document_version != null){
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/contracts/print/'
                        + contract.Sale.document_id
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                        // + '&fileName=' + contract.Sale.title + '.pdf'
                        , "_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/contracts/print/'
                        + contract.Sale.document_id
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                        // + '&fileName=' + contract.Sale.title + '.pdf'
                        , "_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            } else if(contract.Sale.salestype_id == 2){
                if(contract.Sale.document_version != null){
                    if (contract.Sale.SignedOrder.location != null) {
                        let tab = window.open(contract.Sale.SignedOrder.location, "_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        if(contract.Sale.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            // this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                        // this.dialogs.error = true
                        // this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                    }
                } else {
                    if(contract.Sale.TmsSignedOrder.location != null){
                        let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.dialogs.error = true
                        this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                    }
                }
            } else {
                return false
            }
        },
        viewSignedPdf (contract)
        {
            if(contract.Sale.document_version != null){
                let tab = window.open(contract.Sale.SignedOrder.location,"_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else {
                let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        viewPoPdf ( contract ) {
            let document = encodeURIComponent(contract.Sale.title + '_PO')
            if(contract.Sale.document_version != null){
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + contract.Sale.Purchaseorder.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else {
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + contract.Sale.Purchaseorder.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        viewSignedEprPoPdf( epr ){
            let tab = window.open(epr.Sale.SignedPurchaseOrder.location,"_blank")
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewSignedPoPdf( contract ){
            if(contract.Sale.document_version != null){
                let tab = window.open(contract.Sale.SignedPurchaseOrder.location,"_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else {
                let tab = window.open(contract.Sale.TmsSignedPurchaseOrder.location,"_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        viewSalesOrderPdf( salesOrder ) {
            let document = encodeURIComponent(salesOrder.Sale.So.title)
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/salesorders/print/'
                + salesOrder.Sale.So.id
                + '/so/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank")
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewLocalSalesInvoicePdf( localSalesInvoice) {
            let document = encodeURIComponent(localSalesInvoice.Sale.So.title)
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/localsales/print/'
                + localSalesInvoice.Sale.document_id
                + '/si/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank")
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        validateYear (rule) {
            if(this.filterOptions.yearTo != 0 && this.filterOptions.yearFrom != 0 && this.filterOptions.yearTo >= this.filterOptions.yearFrom){
                this.rules.yearFrom = [true]
                this.rules.yearTo = [true]
                this.loadFilterOptions('agents')
                this.loadFilterOptions('buyers')
                this.loadFilterOptions('destinations')
                // this.loadFilterOptions('grades')
                this.loadFilterOptions('grade-groups')
                this.loadFilterOptions('market-segments')
                this.loadFilterOptions('product-groups')
                this.loadFilterOptions('salescolleagues')
                this.loadFilterOptions('sizes')
                this.loadFilterOptions('species')
                this.loadFilterOptions('suppliers')
                this.loadFilterOptions('thickness')
                return
            } else if (this.filterOptions.yearTo == 0 && this.filterOptions.yearFrom > 0) {
                this.rules.yearFrom = [true]
                this.rules.yearTo = [true]
                this.loadFilterOptions('agents')
                this.loadFilterOptions('buyers')
                this.loadFilterOptions('destinations')
                // this.loadFilterOptions('grades')
                this.loadFilterOptions('grade-groups')
                this.loadFilterOptions('market-segments')
                this.loadFilterOptions('product-groups')
                this.loadFilterOptions('salescolleagues')
                // this.loadFilterOptions('sizes')
                this.loadFilterOptions('species')
                this.loadFilterOptions('suppliers')
                this.loadFilterOptions('thickness')
                return
            } else if (this.filterOptions.yearFrom == 0 && this.filterOptions.yearTo > 0) {
                this.rules.yearFrom = [true]
                this.rules.yearTo = [true]
                this.loadFilterOptions('agents')
                this.loadFilterOptions('buyers')
                this.loadFilterOptions('destinations')
                // this.loadFilterOptions('grades')
                this.loadFilterOptions('grade-groups')
                this.loadFilterOptions('market-segments')
                this.loadFilterOptions('product-groups')
                this.loadFilterOptions('salescolleagues')
                // this.loadFilterOptions('sizes')
                this.loadFilterOptions('species')
                this.loadFilterOptions('suppliers')
                this.loadFilterOptions('thickness')
                return
            } else if (this.filterOptions.yearFrom == 0 && this.filterOptions.yearTo == 0) {
                this.rules.yearFrom = [true]
                this.rules.yearTo = [true]
                this.loadFilterOptions('agents')
                this.loadFilterOptions('buyers')
                this.loadFilterOptions('destinations')
                // this.loadFilterOptions('grades')
                this.loadFilterOptions('grade-groups')
                this.loadFilterOptions('market-segments')
                this.loadFilterOptions('product-groups')
                this.loadFilterOptions('salescolleagues')
                // this.loadFilterOptions('sizes')
                this.loadFilterOptions('species')
                this.loadFilterOptions('suppliers')
                this.loadFilterOptions('thickness')
                return
            }
            if(rule == 'yearFrom') this.rules.yearFrom = [this.$t('message.mustBeEarlierThanToYear')]
            if(rule == 'yearTo') this.rules.yearTo = [this.$t('message.mustBeLaterThanFromYear')]
        }
    },
    watch: {
        // searchTerm: {
        //     handler(){
        //         this.$refs.filterForm.validate()
        //         // this.loadSales()
        //     }
        // },
        // filterOptions: {
        //     handler(){
        //         this.$refs.filterForm.validate()
        //        // this.loadSales()
        //     },
        //     deep: true
        // },
        // tableOptions: {
        //     handler(){
        //         this.loadSales()
        //     },
        //     deep: true
        // },
        scrollingList() {
            this.handleResize()
        },
        filterOptions(val) {
            // console.log(val.types)
        }
    },
    mounted(){
        this.handleResize()
        // document.querySelectorAll("*").forEach(element => element.addEventListener("scroll", ({target}) => console.log(target, target.id, target.parent, target.parent.id)));

    },
    created(){
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesAgents'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesCustomers'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesDestinations'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesGrades'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesMarketsegments'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesProductgroups'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesSalescolleagues'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesSizes'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesSpecies'})
        // this.resetSalesFilterOptions({prefix: 'current', filter: 'salesSuppliers'})

        let dt = new Date()
        this.filterOptions.yearFrom = dt.getFullYear() - 1

        window.addEventListener('resize', this.handleResize)
        // window.addEventListener('scroll', this.updateScroll)

        if(this.salesAgents.length == 0) this.loadFilterOptions('agents')
        if(this.salesCustomers.length == 0) this.loadFilterOptions('buyers')
        if(this.salesDestinations.length == 0) this.loadFilterOptions('destinations')
        // if(this.salesGrades.length == 0) this.loadFilterOptions('grades')
        if(this.salesGradeGroups.length == 0) this.loadFilterOptions('grade-groups')
        if(this.salesMarketsegments.length == 0) this.loadFilterOptions('market-segments')
        if(this.salesProductgroups.length == 0) this.loadFilterOptions('product-groups')
        if(this.salesSalescolleagues.length == 0) this.loadFilterOptions('salescolleagues')
        // if(this.salesSizes.length == 0) this.loadFilterOptions('sizes')
        if(this.salesSpecies.length == 0) this.loadFilterOptions('species')
        if(this.salesSuppliers.length == 0) this.loadFilterOptions('suppliers')
        if(this.salesThickness.length == 0) this.loadFilterOptions('thickness')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        // window.removeEventListener('scroll', this.updateScroll)
    },
}
</script>

<style>
    .border-top td {
        border-top: #3C6932 3px solid;
    }
    .border-top td:first-child {
        border-left: #3C6932 3px solid;
    }
    .border-top td:last-child {
        border-right: #3C6932 3px solid;
    }
    .border-bottom td {
        border-bottom: #3C6932 3px solid !important;
    }
    .border-bottom td:first-child {
        border-left: #3C6932 3px solid;
    }
    .border-bottom td:last-child {
        border-right: #3C6932 3px solid;
    }
    .border-left-and-right td:first-child {
        border-left: #3C6932 3px solid;
    }
    .border-left-and-right td:last-child {
        border-right: #3C6932 3px solid;
    }
    .border-top-and-bottom-and-left-and-right td {
        border-top: #3C6932 3px solid;
        border-bottom: #3C6932 3px solid !important;
    }
    .border-top-and-bottom-and-left-and-right td:first-child {
        border-left: #3C6932 3px solid;
    }
    .border-top-and-bottom-and-left-and-right td:last-child {
        border-right: #3C6932 3px solid;
    }
    .font-xs {
        font-size: 0.60rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .text-start {
        padding-left: 3px !important;
        padding-top: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .truncate {
        display: inline-block;
        width: 50px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
    }
    v-input--selection-controls__input {
        margin-right: 0px !important;
    }
    .v-radio.theme--light {
        margin-right: 7px !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
    .v-radio.theme--light label{
        font-size: 0.8rem !important;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
    /*.v-data-table .sticky-header {*/
    /*    position: -webkit-sticky; !* Safari *!*/
    /*    position: sticky;*/
    /*    top: 100px;*/
    /*    !*top: var(--toolbarHeight);*!*/
    /*}*/
    /*.v-data-table .v-data-table__wrapper {*/
    /*    overflow: unset;*/
    /*}*/
    .black-checkbox-outline .v-icon {
        color: black !important;
    }
    .rounded-md {
        border-radius: 4px !important;
    }
</style>